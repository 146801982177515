import React from 'react';
import axios from 'axios';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { NextSeo, WebPageJsonLd } from 'next-seo';
import HomePage from '../components/dashboard/HomePage';
import { ENDPOINT, CANONICAL_URL } from '../api/constants';

function Home({ data }) {
  const { t } = useTranslation('common');
  return (
    <>
      <NextSeo
        title={t('meta.LoginPagePopOut_metaTitle')}
        description={t('meta.LoginPagePopOut_metaDescription')}
        canonical={`${CANONICAL_URL}/login`}
        languageAlternates={[
          {
            hrefLang: 'en-BR',
            href: `${CANONICAL_URL}/en-BR/login`,
          },
          {
            hrefLang: 'pt',
            href: `${CANONICAL_URL}/conecte-se`,
          },
          {
            hrefLang: 'x-default',
            href: `${CANONICAL_URL}/conecte-se`,
          },
        ]}
        openGraph={{
          type: 'website',
          url: `${CANONICAL_URL}/login`,
          title: 'PPX7 - Online Casino Brazil | Fortune Tiger',
          description: 'PPX7 - O melhor cassino online no Brasil com jogos emocionantes como Fortune Tiger.',
          images: [
            {
              url: `${process.env.SITE_URL}/metaImageBackground.jpg`,
              width: 1200,
              height: 800,
              alt: 'PPX7 Image',
            },
          ],
          site_name: 'PPX7',
        }}
      />

      <WebPageJsonLd
        context='https://schema.org'
        type='WebPage'
        name='PPX7 - Online Casino Brazil | Fortune Tiger'
        url={`${CANONICAL_URL}`}
        description='PPX7 - O melhor cassino online no Brasil com jogos emocionantes como Fortune Tiger.'
        inLanguage='pt-BR'
        publisher={{
          type: 'Organization',
          name: 'PPX7',
          logo: {
            type: 'ImageObject',
            url: `${process.env.SITE_URL}/metaImageBackground.jpg`,
          },
        }}
        image={{
          type: 'ImageObject',
          url: `${process.env.SITE_URL}/metaImageBackground.jpg`,
          height: 800,
          width: 1200,
        }}
        potentialAction={{
          type: 'SearchAction',
          target: `${CANONICAL_URL}/search?q={search_term_string}`,
          'query-input': 'required name=search_term_string',
        }}
        mainEntityOfPage={{
          '@type': 'WebPage',
          '@id': `${CANONICAL_URL}`,
        }}
        hasPart={[
          {
            '@type': 'WebPageElement',
            name: 'Bem-vindo',
            text: 'Este é um exemplo de uma página multilíngue.',
          },
          {
            '@type': 'WebPageElement',
            name: 'Our Partners',
            text: 'Our Partners:',
          },
        ]}
        about={{
          '@type': 'Organization',
          name: 'Sigma.org',
          url: 'https://sigma.org',
          logo: {
            '@type': 'ImageObject',
            url: `${process.env.SITE_URL}/metaImageBackground.jpg`,
            caption: 'http://Sigma.org',
          },
        }}
      />
      <HomePage data={data} />
    </>
  );
}

export async function getServerSideProps({ req, locale }) {
  // const { userId, accessToken } = req.cookies;

  const options = {
    method: 'GET',
    url: `${ENDPOINT}/branches`,
    params: { _id: process.env.BRANCH_ID },
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let branchData;
  let banners;

  // get data
  await Promise.all([
    // get branch info
    new Promise((resolve) => axios.request(options)
      .then((result) => resolve(result?.data))
      .catch((err) => resolve(err))),
  ])
    .then(async ([branchInfo]) => {
      banners = branchInfo?.data?.[0]?.banners || [];

      branchData = branchInfo?.data?.[0] || [];
    });
  // get data

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      data: {
        ...branchData,
        branchId: process.env.BRANCH_ID,
        banners,
      },
    },
  };
}

export default Home;
